import { useState } from 'react';
import Head from 'next/head';
import { Global, css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import useEffectOnce from '@ui/hooks/useEffectOnce';
import useToggle from '@ui/hooks/useToggle';
import Icon from '@ui-v2/core/Icon/Icon';
import { resetButton, setCursorOnHover } from '@ui-v2/utils/styleUtils';
import { useAnalytics } from '@web/context/AnalyticsContext';

declare global {
  interface Window {
    zE?: (
      type: 'messenger' | 'messenger:on',
      action: 'open' | 'close',
      callback?: () => void,
    ) => void;
  }
}

const Button = styled.button(
  resetButton,
  setCursorOnHover,
  css`
    height: 24px;
  `,
);

const ChatWidgetLauncher = () => {
  const theme = useTheme();
  const [isChatWidgetEnabled, toggleIsChatWidgetEnabled] = useToggle();
  const { sendChatWindowClosedEvent, sendChatWindowOpenedEvent } =
    useAnalytics();

  const [showChatWidget, setShowChatWidget] = useState(false);

  useEffectOnce(() => {
    const initializeZendesk = () => {
      toggleIsChatWidgetEnabled();
      window.zE?.('messenger:on', 'open', () => setShowChatWidget(true));
      window.zE?.('messenger:on', 'close', () => {
        setShowChatWidget(false);
        sendChatWindowClosedEvent();
      });
    };

    // This event is triggered from GTM when the js script has loaded
    window.addEventListener('zendeskInitialized', initializeZendesk);

    return () =>
      window.removeEventListener('zendeskInitialized', initializeZendesk);
  });

  if (!isChatWidgetEnabled) {
    return null;
  }

  const onToggleWidget = () => {
    window.zE?.('messenger', !showChatWidget ? 'open' : 'close');
    setShowChatWidget(!showChatWidget);

    if (!showChatWidget) {
      sendChatWindowOpenedEvent();
    }
  };

  return (
    <>
      <Head>
        <Global
          styles={css`
            [title='Messaging window'] {
              border-radius: 0;

              /* This is a hardcoded media query when chat widget goes from mobile to desktop */
              @media (width >= 540px) {
                border-radius: ${theme.shape.modal};
              }
            }
          `}
        />
      </Head>
      <Button onClick={onToggleWidget}>
        <Icon colour="components.header.text" size={24} type="chatIcon" />
      </Button>
    </>
  );
};

export default ChatWidgetLauncher;
