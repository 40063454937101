import { forwardRef, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpointsMap } from '@ui-v2/theme/layout';
import useWindowWidth from '@utils/hooks/useWindowWidth';
import { mergeRefs } from '@utils/reactUtils';
import { fadeIn } from '../animations/animations';
import Box, { BoxProps } from '../Box/Box';
import Modal from '../Modal/Modal';

export const MENU_DRAWER_BREAKPOINT = breakpointsMap['768'];

const MenuBox = styled(Box)<Omit<MenuProps, 'onOpenChange'>>(
  ({ align, theme }) => [
    css`
      top: calc(100% + ${theme.spacings[16]}px);
      animation: ${fadeIn} 150ms;
    `,
    align === 'left' &&
      css`
        left: 0;
      `,
    align === 'right' &&
      css`
        right: 0;
      `,
    align === 'center' &&
      css`
        left: 50%;
        transform: translateX(-50%);
      `,
  ],
);

export type MenuProps = {
  align?: 'center' | 'left' | 'right';
  children?: React.ReactNode;
  id: string;
  isOpen?: boolean;
  maxHeight?: BoxProps['maxHeight'];
  onOpenChange?: (isOpen: boolean) => void;
  widthCustom?: BoxProps['widthCustom'];
};

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  (
    { align = 'center', children, id, isOpen = true, onOpenChange, ...props },
    forwardedRef,
  ) => {
    const width = useWindowWidth({ ssr: true });
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const rect = ref.current?.getBoundingClientRect();

      // If a menu goes outside of the screen, realign it to the left
      if (rect && rect.x < 0 && ref.current) {
        ref.current.style.left = '0px';
        ref.current.style.right = 'auto';
      }
    }, [ref, isOpen]);

    return (
      <>
        {isOpen && (
          <MenuBox
            align={align}
            bg="surface.main"
            border="subdued"
            borderRadius="cardPrimary"
            boxShadow="medium"
            display={
              !width || width >= MENU_DRAWER_BREAKPOINT ? 'block' : 'none'
            }
            id={id}
            overflowY="auto"
            position="absolute"
            zIndex="modal"
            {...props}
            ref={mergeRefs([ref, forwardedRef])}
          >
            {children}
          </MenuBox>
        )}

        {width && width < MENU_DRAWER_BREAKPOINT && (
          <Modal
            id={id}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            showCloseButton={false}
          >
            {children}
          </Modal>
        )}
      </>
    );
  },
);

Menu.displayName = 'Menu';

export default Menu;
