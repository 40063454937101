import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createTypography } from '@ui-v2/utils/styleUtils';

export const InputContainer = styled.div<{
  borderRadius?: number;
  hasError: boolean;
  isActive?: boolean;
}>(
  ({
    borderRadius,
    hasError,
    isActive,
    theme: { colours, shape, typography },
  }) => [
    createTypography(typography.body01),
    css`
      position: relative;
      display: flex;
      overflow: hidden;
      width: 100%;
      align-items: center;
      border: 1px solid ${colours.border.default};
      border-radius: ${borderRadius === undefined
        ? shape.input
        : borderRadius}px;
      background-color: ${colours.surface.selected.default};

      &:focus-within {
        border: 1px solid ${colours.border.strong};
        outline: 1px solid ${colours.border.strong};
      }

      > input:focus,
      select:focus,
      input:active,
      select:active {
        outline: none;
      }
    `,
    hasError &&
      css`
        border: 1px solid ${colours.border.critical};
        outline: 1px solid ${colours.border.critical};

        &:focus-within {
          border: 1px solid ${colours.border.critical};
          outline: 1px solid ${colours.border.critical};
        }
      `,
    isActive &&
      css`
        border: 1px solid ${colours.border.strong};
        outline: 1px solid ${colours.border.strong};
      `,
  ],
);
