import {
  TieredServicePackageFragment,
  useGetTieredConnectionServicesQuery,
} from '@codegen/cmsUtils';
import { TierName } from '@codegen/offerAPI';
import { parseQueryString } from '@utils/queryUtils';
import { useConstants } from '@web/context/ConstantContext';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';

export type TieredPackage = Omit<
  TieredServicePackageFragment,
  'serviceTierId'
> & {
  serviceTierId: TierName;
};

const useTieredContent = () => {
  const { locale } = useConstants();
  const {
    query: { is_tiered },
  } = usePartnerRouter();

  const isTieredConnectionService = Boolean(
    parseQueryString(is_tiered) === 'True',
  );

  const { data } = useGetTieredConnectionServicesQuery(
    { locale },
    { retry: 2, enabled: isTieredConnectionService },
  );

  return {
    tieredPackages: data?.allTieredServicePackages as
      | TieredPackage[]
      | undefined,
    allModalServices: data?.tieredConnectionModalService?.modalServices,
  };
};

export default useTieredContent;
